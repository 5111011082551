import { computed, inject } from '@angular/core';
import { signalStore, withComputed, withMethods } from '@ngrx/signals';
import { withSelectableEntityStore } from 'state-data';
import { ProductUnitLabels } from '../../data/unit-labels';
import { ProductPricingStore } from '../../global-stores/product-pricing.store';

export const ProductPricingComponentStore = signalStore(
  withSelectableEntityStore('Pricing', {
    selectEntityById: (id: string) => inject(ProductPricingStore).get(id),
    protected: true,
  }),
  withComputed((store) => {
    const record = computed(() => store._selectedPricing());
    const error = computed(() => store._selectedPricing()?.error);
    const pricingData = computed(() => store._selectedPricing()?.entity?.pricing);
    const isLoading = computed(() => store._selectedPricing()?.loading ?? false);

    const priceLowest = computed(() => {
      const pricing = pricingData();
      if (!pricing) {
        return null;
      }
      const prices = pricing.prices.filter((p) => (p.unitPrice ?? 0) > 0).sort((a, b) => a.unitPrice! - b.unitPrice!);
      return prices[0];
    });

    const priceHighest = computed(() => {
      const pricing = pricingData();
      if (!pricing) {
        return null;
      }
      const prices = pricing.prices.filter((p) => (p.unitPrice ?? 0) > 0).sort((a, b) => a.unitPrice! - b.unitPrice!);
      return prices[prices.length - 1];
    });

    const allowOrders = computed(() => pricingData()?.allowOrders ?? false);
    const allowBackOrders = computed(() => pricingData()?.allowBackOrders ?? false);

    const hasPrice = computed(() => (priceLowest()?.price ?? 0) > 0);
    const soldInMultiplesOf = computed(() => Math.max(1, pricingData()?.soldInMultiplesOf ?? 1));
    const containerQuantity = computed(() => Math.max(1, pricingData()?.containerQuantity ?? 1));

    const pricePerUnit = computed(() => priceLowest()?.unitPrice ?? 0);
    const priceNormalized = computed(() => pricePerUnit() * soldInMultiplesOf());
    const priceBasePerUnit = computed(() => priceHighest()?.unitPrice ?? 0);
    const priceBaseNormalized = computed(() => priceBasePerUnit() * soldInMultiplesOf());

    const pricePerContainer = computed(() => pricePerUnit() * containerQuantity());
    const priceBasePerContainer = computed(() => priceBasePerUnit() * containerQuantity());
    const hasContainerPrice = computed(() => containerQuantity() > 1 && containerQuantity() > soldInMultiplesOf());

    const callForPricing = computed(() => !hasPrice() || !allowOrders());

    const unit = computed(() => (pricingData()?.unit ?? '').toLowerCase());
    const unitLabel = computed(() => {
      const u = unit();
      return (
        ProductUnitLabels[u as keyof typeof ProductUnitLabels] ?? {
          other: u,
        }
      );
    });

    const isSale = computed(() => {
      return priceNormalized() !== priceBaseNormalized();
    });

    return {
      isLoading,
      record,
      error,
      isSale,
      hasPrice,
      priceBaseNormalized,
      priceBasePerContainer,
      pricePerUnit,
      priceNormalized,
      pricePerContainer,
      hasContainerPrice,
      containerQuantity,
      soldInMultiplesOf,
      unit,
      unitLabel,
      callForPricing,
      allowBackOrders,
    };
  }),
  withMethods((store) => {
    return {
      setSelectedProduct: (id: string) => {
        store._setPricingId(id);
      },
    };
  }),
);
