<ideal-loading-container class="block" [loading]="!ready()">
  <ideal-product-section
    i18n-title="@@products.specifications.label"
    title="Specifications"
    navAnchorId="app.main-menu/products/details/specifications"
    navAnchorHeading="Product"
    i18n-navAnchorHeading="@@products.nav.product.heading"
    [navAnchorHidden]="!ready()"
  >
    <div class="specifications">
      @for (specification of specifications(); track $index) {
        <div class="label">{{ specification.label }}</div>
        <div class="values">
          @for (attr of specification.values; track $index) {
            <div class="value">{{ attr.value }}</div>
          }
        </div>
      }
    </div>
  </ideal-product-section>
</ideal-loading-container>
