import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { FileButtonComponent, LoadingContainerComponent } from 'common-ui';
import { ProductDetailRenderTarget, ProductVendorLogoComponent, RenderInProductDetailsLayout } from '../../../components';
import { ProductCommonDetailsBaseComponent } from '../product-common-details-base.component';

@Component({
  selector: 'ideal-product-basic-details',
  imports: [
    RouterModule,
    RenderInProductDetailsLayout,
    LoadingContainerComponent,
    FileButtonComponent,
    MatButtonModule,
    ProductVendorLogoComponent,
  ],
  standalone: true,
  templateUrl: './product-basic-details.component.html',
  styleUrls: ['./product-basic-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductBasicDetailsComponent extends ProductCommonDetailsBaseComponent {
  protected readonly LayoutTarget = ProductDetailRenderTarget;
  protected readonly isLoading = this.store.productLoading;
  protected readonly hasContent = signal(true);

  protected readonly specSheetUrl = computed(() => this.details()?.specSheetUrl ?? '');
}
