import { RenderInTargetType } from 'common-ui';
import { ProductDetailBadgesRenderTargetComponent } from './product-details-badges.render-target.component';
import { ProductDetailDefaultRenderTargetComponent } from './product-details-default.render-target.component';
import { ProductDetailFooterRenderTargetComponent } from './product-details-footer.render-target.component';
import { ProductDetailHeaderRenderTargetComponent } from './product-details-header.render-target.component';
import { ProductDetailIMagesRenderTargetComponent } from './product-details-images.render-target.component';
import { ProductDetailImportantInfoRenderTargetComponent } from './product-details-important-info.render-target.component';
import { ProductDetailInfoRenderTargetComponent } from './product-details-info.render-target.component';
import { ProductDetailLongDescriptionRenderTargetComponent } from './product-details-long-description.render-target.component';
import { ProductDetailToolsRenderTargetComponent } from './product-details-tools.render-target.component';

export enum ProductDetailRenderTarget {
  Default = 'default',
  Header = 'header',
  ImportantInfo = 'important-info',
  Info = 'info',
  Images = 'images',
  Footer = 'footer',
  LongDescription = 'long-description',
  Tools = 'tools',
  Badges = 'badges',
}

export const ProductDetailRenderTargetComponents = {
  [ProductDetailRenderTarget.Default]: ProductDetailDefaultRenderTargetComponent,
  [ProductDetailRenderTarget.Header]: ProductDetailHeaderRenderTargetComponent,
  [ProductDetailRenderTarget.ImportantInfo]: ProductDetailImportantInfoRenderTargetComponent,
  [ProductDetailRenderTarget.Info]: ProductDetailInfoRenderTargetComponent,
  [ProductDetailRenderTarget.Images]: ProductDetailIMagesRenderTargetComponent,
  [ProductDetailRenderTarget.Footer]: ProductDetailFooterRenderTargetComponent,
  [ProductDetailRenderTarget.LongDescription]: ProductDetailLongDescriptionRenderTargetComponent,
  [ProductDetailRenderTarget.Tools]: ProductDetailToolsRenderTargetComponent,
  [ProductDetailRenderTarget.Badges]: ProductDetailBadgesRenderTargetComponent,
} as const;
