<ideal-loading-container class="block" [loading]="!ready()">
  <ideal-product-section
    i18n-title="@@products.crossReferences.label"
    title="Cross References"
    navAnchorId="app.main-menu/products/details/xrefs"
    navAnchorHeading="Product"
    i18n-navAnchorHeading="@@products.nav.product.heading"
    [navAnchorHidden]="isLoading()"
    [navAnchorHidden]="!ready()"
  >
    <form class="cross-references" [formGroup]="form">
      @for (xref of crossReferences(); track $index) {
        <div class="xref">
          <div class="label">
            <div>{{ xref.customerNumber }}</div>
            <div>{{ xref.customerName }}</div>
          </div>
          <div class="value">
            <ideal-text-input
              [formControlName]="xref.customerNumber"
              i18m-label="@@products.crossReferences.label"
              label="Cross References"
              i18n-placeholder="@@products.crossReferences.placeholder"
              placeholder="Enter your reference code here"
            >
            </ideal-text-input>
          </div>
        </div>
      }
    </form>
    <pre>{{ form.value | json }}</pre>
  </ideal-product-section>
</ideal-loading-container>
