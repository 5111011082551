import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';
import { ProductCrossReferenceEntry, ProductCrossReferencesEntity } from './entities/ProductCrossReferencesEntity';
import { CustomerService } from 'lib-customer';
import { withCachedEntityStore } from 'state-data';

export const ProductCrossReferencesStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductCrossReferencesEntity) => p.id,
    load: (productId: string) => {
      const customerService = inject(CustomerService);
      return inject(ProductApi)
        .getCrossReferences({ productId })
        .pipe(
          map((crossReferences) => ({
            id: productId,
            crossReferences: customerService.availableCustomerNumbers
              .filter((cn) => cn.customerNumber !== '000002')
              .map(
                (cnData) =>
                  ({
                    ...cnData,
                    crossReference: crossReferences.find((cr) => cr.customerNumber === cnData.customerNumber)?.crossReference ?? '',
                  }) as ProductCrossReferenceEntry,
              ),
          })),
        );
    },
    cacheSeconds: 60,
  }),
);
