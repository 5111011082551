import { inject } from '@angular/core';
import { StandardProductItemsApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { AuthenticationService } from 'authentication-data';

import { map } from 'rxjs';
import { withCachedEntityStore } from 'state-data';
import { ProductPricingEntity } from './entities/ProductPricingEntity';
import { productIdentifierToString, stringToProductItemIdentifier } from '../ProductUtil';

export const ProductPricingStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductPricingEntity) => p.id,
    load: (productId: string) => {
      const user = inject(AuthenticationService).user;
      var id = stringToProductItemIdentifier(productId);
      return inject(StandardProductItemsApi)
        .getProductItemPricing({ productId: productIdentifierToString(id) })
        .pipe(
          map((r) => ({
            id: productId,
            pricing: r,
          })),
        );
    },
    cacheSeconds: 60,
  }),
);
