<ideal-loading-container class="block" [loading]="!ready()">
  <ideal-product-section
    i18n-title="@@products.productAlternates.label"
    title="Similar Products"
    navAnchorId="app.main-menu/products/details/similar"
    navAnchorHeading="Product"
    i18n-navAnchorHeading="@@products.nav.product.heading"
    [navAnchorHidden]="!ready()"
  >
    <div class="items">
      @for (item of alternateProducts(); track $index) {
        <ideal-product-card [product]="item.id"></ideal-product-card>
      }
    </div>
    <!-- <div class="alternates">
      @for (item of alternateProducts(); track $index) {
        <pre>{{ item | json }}</pre>
      } @empty {
        <div class="no-alternates">
          <p i18n="@@products.product-alternates.none-found">No similar products found.</p>
        </div>
      }
    </div> -->
  </ideal-product-section>
</ideal-loading-container>
