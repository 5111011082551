import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { delay, map } from 'rxjs';
import { ProductSpecEntity } from './entities/ProductSpecEntity';
import { withCachedEntityStore } from 'state-data';

export const ProductSpecificationsStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductSpecEntity) => p.id,
    load: (productId: string) =>
      inject(ProductApi)
        .getSpecs({ productId })
        .pipe(
          map((specifications) => ({ id: productId, specifications })),
          // delay(5000),
        ),
    cacheSeconds: 60,
  }),
);
