import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'ideal-product-long-description',
  imports: [],
  standalone: true,
  templateUrl: './product-long-description.component.html',
  styleUrl: './product-long-description.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductLongDescriptionComponent {
  public readonly description = input<string>();
}
