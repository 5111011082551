import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingContainerComponent } from 'common-ui';
import { ProductDetailsBaseComponent } from '../product-details-base.component';
import { ProductListItemStockComponent } from 'lib-products';
import { BranchStockLevel, ProductFee, ProductListItem, ProductPrice } from '@idealsupply/ngclient-webservice-inventory';

@Component({
  selector: 'ideal-product-stock',
  imports: [LoadingContainerComponent, MatButtonModule, MatIconModule, ProductListItemStockComponent, JsonPipe],
  standalone: true,
  templateUrl: './product-stock.component.html',
  styleUrl: './product-stock.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductStockComponent extends ProductDetailsBaseComponent {
  // protected readonly isSection = computed(() => this.layout() !== ProductDetailsLayout.ThreeCol);
  protected readonly isLoading = computed(() => this.store.productLoading() || this.store.stockLoading() || this.store.pricingLoading());
  protected readonly stock = computed(() => this.store.stock() ?? null);
  protected readonly hasContent = computed(() => this.stock() !== null);

  protected readonly legacyListItem = computed(() => {
    const productDetails = this.store.product();
    const stockDetails = this.store.stockDetails();
    const priceDetails = this.store.pricing();
    if (!productDetails || !stockDetails || !priceDetails) {
      return undefined;
    }
    const fees = priceDetails.fees ?? [];
    const pricing =
      priceDetails?.prices.map(
        (price) =>
          ({
            source: price.type,
            value: price.price ?? 0,
            valuePer: price.pricePer ?? 1,
            sourceId: price.customerNumber || price.promotionId || price.quoteNumber,
            sourceLineNumber: price.quoteLine ? +price.quoteLine : undefined,
          }) as ProductPrice,
      ) ?? [];
    const stock =
      stockDetails.stock.map(
        (branchStock) =>
          ({
            branch: branchStock.branchId,
            name: branchStock.branchName,
            quantityBackOrdered: branchStock.quantityBackOrdered,
            stock: branchStock.quantity,
          }) as BranchStockLevel,
      ) ?? [];
    const result: ProductListItem = {
      lineCode: productDetails.id.lineCode,
      partNumber: productDetails.id.partNumber,
      fees,
      pricing,
      stock,
      allowBackorders: stockDetails.backOrdersAllowed,
      allowOrders: stockDetails.ordersAllowed,
      boxQuantity: productDetails.boxQuantity,
      description: productDetails.description,
      // canadian: product.canadian,
      canadian: false,
      sellMultiple: priceDetails?.soldInMultiplesOf ?? 1,
      statusCode: productDetails.statusCode,
      unit: productDetails.unit,
      vendorName: productDetails.vendor.name,
      vendorNum: productDetails.vendor.vendorNumber,
      returnable: productDetails.returnable,
    };

    return result;
  });

  protected readonly hasHomeBranch = computed(() => !!this.store.homeBranch());
  protected readonly homeBranchHasStock = computed(() => this.store.homeBranchHasStock());

  protected readonly branchesWithStock = computed(() => this.stock().filter((branch) => branch.quantity > 0));
  protected readonly numBranchesWithStock = computed(() => this.branchesWithStock().length);
  protected readonly totalStock = computed(() => this.stock().reduce((acc, branch) => acc + branch.quantity, 0));
  protected readonly hasStock = computed(() => this.totalStock() > 0);
  protected readonly ordersAllowed = computed(() => this.store.stockDetails()?.ordersAllowed ?? true);
  protected readonly backOrdersAllowed = computed(() => this.store.stockDetails()?.backOrdersAllowed ?? true);

  protected readonly state = computed(() => {
    if (this.isLoading()) {
      return 'loading';
    } else if (!this.ordersAllowed()) {
      return 'disabled';
    } else if (this.homeBranchHasStock()) {
      return 'home-stock';
    } else if (this.hasHomeBranch() && this.hasStock()) {
      return 'in-stock-other';
    } else if (this.hasStock()) {
      return 'in-stock';
    } else if (this.backOrdersAllowed()) {
      return 'back-order';
    } else {
      return 'sold-out';
    }
  });
}
