@let anchor = navAnchor();
<div
  [idealNavAnchor]="anchor?.id"
  [navLabel]="anchor?.label ?? ''"
  [navId]="anchor?.parentId"
  [navHeading]="anchor?.heading ?? ''"
  [navPriority]="anchor?.priority"
  [navHidden]="navAnchorHidden()"
  [navDisabled]="anchor?.disabled ?? false"
  [navIcon]="anchor?.icon"
>
  @if (showTitle()) {
    <h2>{{ title() }}</h2>
  }
  <div>
    <ng-content></ng-content>
  </div>
</div>
