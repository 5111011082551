<ideal-carousel
  [data]="images()"
  [layoutStrategy]="'maxItemFit'"
  xpageDurationx="10000"
  [pauseTimer]="true"
  [hideNextAndPrev]="!showControls()"
  [controlPosition]="showControls() ? 'overlay-bottom' : 'hidden'"
>
  <div class="image-container" *carouselItem="let item">
    <ideal-image [src]="item" />
  </div>
</ideal-carousel>
