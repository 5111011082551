import { ChangeDetectionStrategy, Component, computed, signal } from '@angular/core';
import { ImageComponent } from 'common-ui';
import { CarouselComponent, CarouselItemDirective } from 'page-layouts-ui';
import { ProductCommonDetailsBaseComponent } from '../product-common-details-base.component';

@Component({
  selector: 'ideal-product-image-viewer',
  imports: [CarouselComponent, CarouselItemDirective, ImageComponent],
  standalone: true,
  templateUrl: './product-image-viewer.component.html',
  styleUrl: './product-image-viewer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductImageViewerComponent extends ProductCommonDetailsBaseComponent {
  protected readonly isLoading = signal(false);

  protected readonly images = computed(() => {
    const product = this.details();
    if (!product || !product.id || !product.id.lineCode || !product.id.partNumber) {
      return [];
    }
    const length = product.images ?? 1;
    return Array.from(
      { length },
      (_, i) => `https://api.idealsupply.com/products/v0/${product.id!.lineCode}${product.id!.partNumber}/thumbnail?ImageIndex=${i}`,
    );
  });
  protected readonly hasContent = computed(() => this.images().length !== 0);
  protected readonly showControls = computed(() => this.images().length > 1);
}
