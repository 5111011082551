import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ProductDetailRenderTarget, RenderInProductDetailsLayout } from '../../../components';
import { ProductBasicDetailsComponent, ProductImageViewerComponent } from '../../common';
import { ProductAlternatesComponent } from '../../common/product-alternates/product-alternates.component';
import { ProductCertsComponent } from '../../common/product-certs/product-certs.component';
import { ProductComplimentaryComponent } from '../../common/product-complimentary/product-complimentary.component';
import { ProductCrossReferencesComponent } from '../../common/product-cross-references/product-cross-references.component';
import { ProductLinksComponent } from '../../common/product-links/product-links.component';
import { ProductSpecificationsComponent } from '../../common/product-specifications/product-specifications.component';
import { ProductDescriptionComponent } from './components/product-description/product-description.component';
import { ProductDetailsBaseComponent } from './components/product-details-base.component';
import { ProductStockComponent } from './components/product-stock/product-stock.component';
import { ProductPurchaseComponent } from './components/product-purchase/product-purchase.component';

@Component({
  selector: 'ideal-product-details',
  imports: [
    RenderInProductDetailsLayout,
    ProductBasicDetailsComponent,
    ProductImageViewerComponent,
    ProductSpecificationsComponent,
    ProductAlternatesComponent,
    ProductCertsComponent,
    ProductComplimentaryComponent,
    ProductCrossReferencesComponent,
    ProductLinksComponent,
    ProductDescriptionComponent,
    ProductStockComponent,
    ProductPurchaseComponent,
  ],
  standalone: true,
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailsComponent extends ProductDetailsBaseComponent {
  protected readonly LayoutTarget = ProductDetailRenderTarget;
  protected readonly isLoading = this.store.productLoading;
  protected readonly hasContent = computed(() => true);
}
