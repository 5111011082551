import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';
import { ProductLinksEntity } from './entities/ProductLinksEntity';
import { withCachedEntityStore } from 'state-data';

export const ProductLinksStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductLinksEntity) => p.id,
    load: (productId: string) =>
      inject(ProductApi)
        .getLinks({ productId })
        .pipe(map((links) => ({ id: productId, links }))),
    cacheSeconds: 60,
  }),
);
