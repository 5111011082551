@let layout = pageLayout();
<ideal-one-column-layout>
  <div layout-container>
    <div
      top
      i18n-navLabel="@@products.details.label"
      navLabel="Details"
      idealNavAnchor="app.main-menu/products/details/"
      [navHidden]="false"
      i18n-navHeading="@@products.nav.product.heading"
      navHeading="Product"
    ></div>
    <div header *ifHasRenderInSources="LayoutComponents[LayoutTargets.Header]">
      <ideal-render-target-product-details-header />
    </div>

    @if (layout === Layout.OneCol) {
      <div important-info *ifHasRenderInSources="LayoutComponents[LayoutTargets.ImportantInfo]">
        <ideal-render-target-product-details-important-info />
      </div>
    }

    <div container>
      <div class="col1" images *ifHasRenderInSources="LayoutComponents[LayoutTargets.Images]">
        <ideal-render-target-product-details-images />
      </div>
      @if (layout === Layout.TwoCol || layout === Layout.TwoColSmall || layout === Layout.ThreeCol) {
        <div class="col2">
          <div important-info *ifHasRenderInSources="LayoutComponents[LayoutTargets.ImportantInfo]">
            <ideal-render-target-product-details-important-info />
          </div>
          <div info *ifHasRenderInSources="LayoutComponents[LayoutTargets.Info]">
            <ideal-render-target-product-details-info />
          </div>
          <div badges *ifHasRenderInSources="LayoutComponents[LayoutTargets.Badges]">
            <ideal-render-target-product-details-badges />
          </div>
        </div>
      }
      @if (layout === Layout.ThreeCol) {
        <div class="col3">
          <div long-description *ifHasRenderInSources="LayoutComponents[LayoutTargets.LongDescription]">
            <ideal-render-target-product-details-long-description />
          </div>
          <div tools *ifHasRenderInSources="LayoutComponents[LayoutTargets.Tools]">
            <ideal-render-target-product-details-tools />
          </div>
        </div>
      }
    </div>

    @if (layout === Layout.TwoCol || layout === Layout.TwoColSmall) {
      <div long-description *ifHasRenderInSources="LayoutComponents[LayoutTargets.LongDescription]">
        <ideal-render-target-product-details-long-description />
      </div>
      <div tools *ifHasRenderInSources="LayoutComponents[LayoutTargets.Tools]">
        <ideal-render-target-product-details-tools />
      </div>
    } @else if (layout === Layout.OneCol) {
      <div>
        <div info *ifHasRenderInSources="LayoutComponents[LayoutTargets.Info]">
          <ideal-render-target-product-details-info />
        </div>
        <div badges *ifHasRenderInSources="LayoutComponents[LayoutTargets.Badges]">
          <ideal-render-target-product-details-badges />
        </div>
        <div tools *ifHasRenderInSources="LayoutComponents[LayoutTargets.Tools]">
          <ideal-render-target-product-details-tools />
        </div>
        <div long-description *ifHasRenderInSources="LayoutComponents[LayoutTargets.LongDescription]">
          <ideal-render-target-product-details-long-description />
        </div>
      </div>
    }
    <div default *ifHasRenderInSources="LayoutComponents[LayoutTargets.Default]">
      <ideal-render-target-product-details-default />
    </div>
    <div footer *ifHasRenderInSources="LayoutComponents[LayoutTargets.Footer]">
      <ideal-render-target-product-details-footer />
    </div>
    <!--
  </div> -->

    <!--


  <ideal-render-target-product-details-header />
  <ideal-render-target-product-details-important-info />
  <ideal-render-target-product-details-images />
  <ideal-render-target-product-details-info />
  <ideal-render-target-product-details-badges />
  <ideal-render-target-product-details-tools />
  <ideal-render-target-product-details-long-description />
  <ideal-render-target-product-details-default />
  <ng-content></ng-content>
   -->
  </div>
</ideal-one-column-layout>
<!--
<ideal-one-column-layout>
  <div layout>
    <div header-container>
      <ng-content select="[header]"></ng-content>
    </div>
    <div image-viewer-container>
      <ng-content select="[image-viewer]"></ng-content>
    </div>
    <div product-info-group>
      <div product-info-important-container>
        <ng-content select="[i-info]"></ng-content>
      </div>
      <div product-info-container>
        <ng-content select="[info]"></ng-content>
      </div>
    </div>

    <div description-container>
      <ng-content select="[description]"></ng-content>
    </div>
    <div vendor-container>
      <ng-content select="[vendor]"></ng-content>
    </div>
    <div certs-container>
      <ng-content select="[certs]"></ng-content>
    </div>
    <div specs-container>
      <ng-content select="[main-specs]"></ng-content>
    </div>
    <div tools-container>
      <ng-content select="[tools]"></ng-content>
    </div>
    <div additional-content-container>
      <ng-content></ng-content>
    </div>
    <div footer-container>
      <ng-content select="[footer]"></ng-content>
    </div>
  </div>
</ideal-one-column-layout> -->
