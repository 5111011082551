import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FileButtonComponent, LoadingContainerComponent } from 'common-ui';
import { ProductSectionComponent } from '../../../components/product-section/product-section.component';
import { ProductCommonDetailsBaseComponent } from '../product-common-details-base.component';

@Component({
  selector: 'ideal-product-links',
  imports: [ProductSectionComponent, LoadingContainerComponent, MatButtonModule, FileButtonComponent],
  standalone: true,
  templateUrl: './product-links.component.html',
  styleUrls: ['../styles/product-base.scss', '../styles/loading.scss', './product-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.loading]': 'isLoading()',
    '[class.no-content]': '!hasContent()',
  },
})
export class ProductLinksComponent extends ProductCommonDetailsBaseComponent {
  protected readonly isLoading = this.store.linksLoading;
  protected readonly links = computed(() => this.store.links() ?? []);
  protected readonly hasContent = computed(() => this.links().length !== 0);
}
