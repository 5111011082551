@let product = details();

<mat-card class="product-card">
  <div mat-card-image>
    <ideal-image [src]="image()"></ideal-image>
  </div>
  <mat-card-header>
    <mat-card-title
      ><a [routerLink]="productCmd()" [relativeTo]="relativeTo()">{{ product?.description }}</a></mat-card-title
    >
    <mat-card-subtitle
      ><a [routerLink]="productCmd()" [relativeTo]="relativeTo()">{{ productIdString() }}</a></mat-card-subtitle
    >
  </mat-card-header>
  <mat-card-actions>
    <ng-container *ngComponentOutlet="typeComponent()"></ng-container>
  </mat-card-actions>
</mat-card>
