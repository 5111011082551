<ideal-loading-container class="block" [loading]="!ready()">
  <ideal-product-section
    i18n-title="@@products.description.label"
    title="Description"
    [titleHidden]="!isSection()"
    navAnchorId="app.main-menu/products/details/description"
    navAnchorHeading="Product"
    i18n-navAnchorHeading="@@products.nav.product.heading"
    [navAnchorHidden]="hideNavItem()"
  >
    <ideal-product-long-description [description]="typedDetails().longDescription" />
  </ideal-product-section>
</ideal-loading-container>
