<ideal-loading-container [loading]="store.isLoading()">
  <div price-info>
    @if (store.callForPricing()) {
      <div call-for-price><mat-icon>phone</mat-icon>Price</div>
    } @else {
      <div price>{{ store.priceNormalized() | currency }}</div>
      @if (!hideUnits()) {
        <div per>{{ store.soldInMultiplesOf() | number }}</div>
        <div unit>{{ store.soldInMultiplesOf() | i18nPlural: store.unitLabel() }}</div>
      }
    }
  </div>
</ideal-loading-container>
