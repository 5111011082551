import { booleanAttribute, ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { NavAnchorDirective } from 'navigation-ui';

@Component({
  selector: 'ideal-product-section',
  imports: [NavAnchorDirective],
  standalone: true,
  templateUrl: './product-section.component.html',
  styleUrl: './product-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSectionComponent {
  public readonly title = input<string>();
  public readonly titleHidden = input<boolean, unknown>(false, { transform: booleanAttribute });

  public readonly navAnchorDisabled = input<boolean, unknown>(false, { transform: booleanAttribute });
  public readonly navAnchorHidden = input<boolean, unknown>(true, { transform: booleanAttribute });
  public readonly navAnchorLabel = input<string>();
  public readonly navAnchorId = input<string>();
  public readonly navAnchorHeading = input<string>();
  public readonly navAnchorIcon = input<string>();
  public readonly navAnchorPriority = input<number>();

  protected readonly showTitle = computed(() => !this.titleHidden() && !!this.title()?.trim());

  protected readonly navAnchor = computed(() => {
    if (!this.navAnchorId()) {
      return null;
    }

    const parts = this.navAnchorId()!.split('/');
    const id = parts.pop();
    const parentId = parts.join('/');

    return {
      id,
      parentId,
      label: this.navAnchorLabel()?.trim() || this.title()?.trim(),
      disabled: this.navAnchorDisabled(),
      hidden: this.navAnchorHidden,
      heading: this.navAnchorHeading(),
      priority: this.navAnchorPriority(),
      icon: this.navAnchorIcon(),
    };
  });
}
