import { ChangeDetectionStrategy, Component, computed } from '@angular/core';

import { LoadingContainerComponent } from 'common-ui';
import { ProductDetailsLayout } from 'products-data';
import { ProductLongDescriptionComponent } from '../../../../../components';
import { ProductSectionComponent } from '../../../../../components/product-section/product-section.component';
import { ProductDetailsBaseComponent } from '../product-details-base.component';

@Component({
  selector: 'ideal-product-description',
  imports: [ProductLongDescriptionComponent, ProductSectionComponent, LoadingContainerComponent],
  standalone: true,
  templateUrl: './product-description.component.html',
  styleUrls: [
    '../../../../common/styles/product-base.scss',
    '../../../../common/styles/loading.scss',
    './product-description.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.loading]': 'isLoading()',
    '[class.no-content]': '!hasContent()',
  },
})
export class ProductDescriptionComponent extends ProductDetailsBaseComponent {
  protected readonly isSection = computed(() => this.layout() !== ProductDetailsLayout.ThreeCol);
  protected readonly isLoading = this.store.productLoading;
  protected readonly hasContent = computed(() => (this.typedDetails().description?.trim() ?? '') !== '');
  protected readonly hideNavItem = computed(() => !this.ready() || !this.isSection());
}
