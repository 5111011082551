export enum ProjectDetailsLayoutContentTarget {
  Header = 'header',
  ImageViewer = 'image-viewer',
  ImportantInfo = 'i-info',
  Info = 'info',
  Description = 'description',
  Vendor = 'vendor',
  Certs = 'certs',
  MainSpecs = 'main-specs',
  Tools = 'tools',
  Footer = 'footer',
  Default = '*',
}
