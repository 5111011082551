import { ProductIdentifier, ProductItemIdentifier, ProductType } from '@idealsupply/products-api-angular-client';

export function productIdentifierToString(id: ProductIdentifier): string {
  return `${id.lineCode}${id.partNumber}`;
}

export function stringToProductIdentifier(id: string): ProductIdentifier {
  if (id.length < 3) {
    throw new Error('Invalid product identifier');
  }
  return {
    lineCode: id.substring(0, 3),
    partNumber: id.substring(3),
  };
}

export function productIdentifierAttribute(attr: ProductIdentifier | string): ProductIdentifier {
  return typeof attr === 'string' ? stringToProductIdentifier(attr) : attr;
}

export function productItemIdentifierToString(id: ProductItemIdentifier): string {
  return `${id.productType}|${id.productOptions}|${id.lineCode}${id.partNumber}`;
}

export function stringToProductItemIdentifier(id: string): ProductItemIdentifier {
  const parts = id.split('|');
  if (parts.length < 3) {
    throw new Error('Invalid product item identifier');
  }

  const productType = parts[0] as ProductType;
  const productOptions = parts[1];
  const productId = stringToProductIdentifier(parts[2]);

  return {
    ...productId,
    productType,
    productOptions,
  };
}

export function productItemIdentifierAttribute(attr: ProductItemIdentifier | string): ProductItemIdentifier {
  return typeof attr === 'string' ? stringToProductItemIdentifier(attr) : attr;
}
