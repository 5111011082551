import { computed, inject } from '@angular/core';
import { Product, ProductDetails } from '@idealsupply/products-api-angular-client';
import { signalStore, withComputed, withMethods } from '@ngrx/signals';
import { ProductDetailsStore, ProductStore } from '../../global-stores';

// import { ProductDetailsLayout } from './ProductDetailsLayout';
import { EntityRecord, withSelectableEntityStore } from 'state-data';
import { ProductStockStore } from '../../global-stores/product-stock.store';
import { ProductStockLocationStore } from '../../global-stores/product-stock-location.store';
import { ProductPricingStore } from '../../global-stores/product-pricing.store';

export const ProductCardComponentStore = signalStore(
  withSelectableEntityStore('Product', {
    selectEntityById: (id: string) => inject(ProductDetailsStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('Stock', {
    selectEntityById: (id: string) => inject(ProductStockStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('StockLocation', {
    selectEntityById: (id: string) => inject(ProductStockLocationStore).get(id),
    protected: true,
  }),
  withSelectableEntityStore('Pricing', {
    selectEntityById: (id: string) => inject(ProductPricingStore).get(id),
    protected: true,
  }),
  withComputed((store) => {
    const productStore = inject(ProductStore);
    const productRecord = computed<EntityRecord<ProductDetails>>(() => {
      const result = {
        loading: false,
        error: undefined,
      } as EntityRecord<ProductDetails>;

      const productId = store._selectedProductId();
      if (productId === undefined) {
        return result;
      }

      let prod: Product | undefined;

      if (productStore.isCacheValid(productId)) {
        prod = productStore.get(productId)().entity;
      }

      const productDetails = store._selectedProduct();
      Object.assign(result, productDetails);
      if (prod) {
        result.entity ??= {} as ProductDetails;
        Object.assign(result.entity, prod);
      }
      return result;
    });

    const stockRecord = computed(() => store._selectedStock());
    const stock = computed(() => stockRecord()?.entity?.stock ?? []);
    const stockDetails = computed(() => stockRecord()?.entity);
    const totalStockAmount = computed(() => stock().reduce((acc, s) => acc + s.quantity, 0));
    const hasStock = computed(() => totalStockAmount() > 0);

    const pricingRecord = computed(() => store._selectedPricing());
    const pricingDetails = computed(() => pricingRecord()?.entity);
    const pricing = computed(() => pricingDetails()?.pricing);
    const pricingLoading = computed(() => pricingRecord()?.loading ?? false);

    return {
      productRecord,
      product: computed(() => productRecord()?.entity),
      productError: computed(() => productRecord()?.error),
      productLoading: computed(() => productRecord()?.loading ?? false),
      stockRecord,
      stock,
      stockDetails,
      stockLoading: computed(() => store._selectedStock()?.loading ?? false),
      totalStockAmount,
      hasStock,
      pricingRecord,
      pricing,
      pricingDetails,
      pricingLoading,
    };
  }),
  withMethods((store) => {
    return {
      setSelectedProduct: (id: string) => {
        store._setProductId(id);
        store._setStockId(id);
        store._setStockLocationId(id);
        store._setPricingId(`PRODUCT||${id}`);
      },
    };
  }),
);
