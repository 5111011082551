import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { BranchStockLevel, ProductListItem, ProductPrice } from '@idealsupply/ngclient-webservice-inventory';
import { ProductItemIdentifier, ProductType } from '@idealsupply/products-api-angular-client';
import { LoadingContainerComponent } from 'common-ui';
import { CartAddRemoveButtonComponent } from 'lib-cart';
import { ProductPriceComponent } from '../../../../../components';
import { ProductDetailsBaseComponent } from '../product-details-base.component';

@Component({
  selector: 'ideal-product-purchase',
  imports: [LoadingContainerComponent, CartAddRemoveButtonComponent, ProductPriceComponent, JsonPipe],
  standalone: true,
  templateUrl: './product-purchase.component.html',
  styleUrl: './product-purchase.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPurchaseComponent extends ProductDetailsBaseComponent {
  protected readonly isLoading = computed(() => this.store.stockLoading());
  protected readonly stock = computed(() => this.store.stockDetails() ?? null);
  // protected readonly pricing = computed(() => this.store.pricing() ?? null);
  protected readonly hasContent = computed(() => !!this.productItem());
  protected readonly productItemId = computed(() => {
    const details = this.typedDetails();
    return {
      partNumber: details.id.partNumber,
      lineCode: details.id.lineCode,
      productType: ProductType.Product,
      productOptions: '',
    } as ProductItemIdentifier;
  });

  protected readonly productItem = computed(() => {
    const productDetails = this.typedDetails();
    const stockDetails = this.store.stockDetails();
    const priceDetails = this.store.pricing();
    if (!productDetails || !stockDetails || !priceDetails) {
      return undefined;
    }
    const fees = priceDetails.fees ?? [];

    const pricing =
      priceDetails?.prices.map(
        (price) =>
          ({
            source: price.type,
            value: price.price ?? 0,
            valuePer: price.pricePer ?? 1,
            sourceId: price.customerNumber || price.promotionId || price.quoteNumber,
            sourceLineNumber: price.quoteLine ? +price.quoteLine : undefined,
          }) as ProductPrice,
      ) ?? [];

    const stock =
      stockDetails.stock.map(
        (branchStock) =>
          ({
            branch: branchStock.branchId,
            name: branchStock.branchName,
            quantityBackOrdered: branchStock.quantityBackOrdered,
            stock: branchStock.quantity,
          }) as BranchStockLevel,
      ) ?? [];

    const result: ProductListItem = {
      lineCode: productDetails.id.lineCode,
      partNumber: productDetails.id.partNumber,
      fees,
      pricing,
      stock,
      allowBackorders: stockDetails.backOrdersAllowed,
      allowOrders: stockDetails.ordersAllowed,
      boxQuantity: productDetails.boxQuantity,
      description: productDetails.description,
      // canadian: product.canadian,
      canadian: false,
      sellMultiple: priceDetails?.soldInMultiplesOf ?? 1,
      statusCode: productDetails.statusCode,
      unit: productDetails.unit,
      vendorName: productDetails.vendor.name,
      vendorNum: productDetails.vendor.vendorNumber,
      returnable: productDetails.returnable,
    };
    return result;

    // // const pricingDetails = this.pricing();

    // if (!productDetails || !stockDetails) {
    //   return undefined;
    // }

    // return {
    //   partNumber: productDetails.id.partNumber,
    //   lineCode: productDetails.id.lineCode,
    //   allowBackorders: stockDetails.backOrdersAllowed ?? false,
    //   allowOrders: stockDetails.ordersAllowed ?? false,
    //   boxQuantity: productDetails.boxQuantity,
    //   description: productDetails.description,
    //   // sellMultiple: pricingDetails.soldInMultiplesOf ?? 1,
    //   unit: productDetails.unit,
    //   vendorName: productDetails.vendor.name,
    //   vendorNum: productDetails.vendor.vendorNumber,
    //   statusCode: productDetails.statusCode,
    //   returnable: productDetails.returnable,
    //   // fees: pricingDetails.fees,
    //   // pricing: pricingDetails.prices.map((p) => ({
    //   //   source: p.type,
    //   //   value: p.price,
    //   //   valuePer: p.pricePer,
    //   // })),
    //   stock: stockDetails.stock.map((branch) => ({
    //     branch: branch.branchId,
    //     name: branch.branchName,
    //     stock: branch.quantity,
    //     quantityBackOrdered: branch.quantityBackOrdered,
    //   })),
    // } as ProductListItem;
  });
}
