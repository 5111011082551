<ideal-loading-container class="block" [loading]="!ready()">
  <product-list-item-stock [item]="legacyListItem()" />
  <!-- stock state: {{ state() }} -->
  <!-- @if(hasHomeBranch()){

  }@else {

    <button mat-stroked-button><mat-icon>check_circle</mat-icon></button>
  } -->
  <!-- <pre>{{ stock() | json }}</pre> -->
</ideal-loading-container>
