import { CurrencyPipe, DecimalPipe, I18nPluralPipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { LoadingContainerComponent } from 'common-ui';
import { productItemIdentifierAttribute, productItemIdentifierToString, ProductPricingComponentStore } from 'products-data';

@Component({
  selector: 'ideal-product-price',
  imports: [LoadingContainerComponent, MatIconModule, NgTemplateOutlet, CurrencyPipe, DecimalPipe, I18nPluralPipe],
  standalone: true,
  templateUrl: './product-price.component.html',
  styleUrl: './product-price.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductPricingComponentStore],
  host: {
    '[class.sale]': 'store.isSale()',
  },
})
export class ProductPriceComponent {
  public readonly productId = input.required({ transform: productItemIdentifierAttribute });

  protected readonly store = inject(ProductPricingComponentStore);

  protected readonly mainPrice = computed(() => ({
    priceBase: this.store.priceBaseNormalized(),
    price: this.store.priceNormalized(),
    pricePer: this.store.soldInMultiplesOf(),
    isSale: this.store.isSale(),
  }));

  protected readonly containerPrice = computed(() => ({
    priceBase: this.store.priceBasePerContainer(),
    price: this.store.pricePerContainer(),
    pricePer: this.store.containerQuantity(),
    isSale: this.store.isSale(),
  }));
  protected readonly hasContainerPrice = computed(() => this.store.hasContainerPrice());

  constructor() {
    effect(() => {
      const id = this.productId();
      this.store.setSelectedProduct(productItemIdentifierToString(id));
    });
  }
}
