import { DecimalPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { BranchStockLevel, ProductListItem, ProductPrice } from '@idealsupply/ngclient-webservice-inventory';
import { ProductItemIdentifier, ProductType } from '@idealsupply/products-api-angular-client';
import { LoadingContainerComponent } from 'common-ui';
import { CartAddRemoveButtonComponent } from 'lib-cart';
import { ProductListItemStockComponent } from 'lib-products';

import { ProductPriceSimpleComponent } from '../../../product/components';
import { ProductCardProductDetailBaseComponent } from '../ProductCardProductDetailBaseComponent';
import { productItemIdentifierToString } from 'products-data';

@Component({
  selector: 'ideal-product-card-details',
  imports: [
    ProductListItemStockComponent,
    LoadingContainerComponent,
    CartAddRemoveButtonComponent,
    ProductPriceSimpleComponent,
    DecimalPipe,
  ],
  templateUrl: './product-card-details.component.html',
  styleUrl: './product-card-details.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardDetailsComponent extends ProductCardProductDetailBaseComponent {
  protected readonly hasContent = computed(() => !!this.store.stockDetails() && !!this.store.pricingDetails() && !!this.store.product());
  protected readonly isLoading = computed(() => this.store.productLoading() || this.store.stockLoading() || this.store.pricingLoading());

  protected readonly productItemId = computed(() => {
    const details = this.typedDetails();
    return productItemIdentifierToString({
      partNumber: details.id.partNumber,
      lineCode: details.id.lineCode,
      productType: ProductType.Product,
      productOptions: '',
    } as ProductItemIdentifier);
  });

  protected readonly legacyListItem = computed(() => {
    const productDetails = this.store.product();
    const stockDetails = this.store.stockDetails();
    const priceDetails = this.store.pricing();
    if (!productDetails || !stockDetails || !priceDetails) {
      return undefined;
    }
    const fees = priceDetails.fees ?? [];
    const pricing =
      priceDetails?.prices.map(
        (price) =>
          ({
            source: price.type,
            value: price.price ?? 0,
            valuePer: price.pricePer ?? 1,
            sourceId: price.customerNumber || price.promotionId || price.quoteNumber,
            sourceLineNumber: price.quoteLine ? +price.quoteLine : undefined,
          }) as ProductPrice,
      ) ?? [];
    const stock =
      stockDetails.stock.map(
        (branchStock) =>
          ({
            branch: branchStock.branchId,
            name: branchStock.branchName,
            quantityBackOrdered: branchStock.quantityBackOrdered,
            stock: branchStock.quantity,
          }) as BranchStockLevel,
      ) ?? [];
    const result: ProductListItem = {
      lineCode: productDetails.id.lineCode,
      partNumber: productDetails.id.partNumber,
      fees,
      pricing,
      stock,
      allowBackorders: stockDetails.backOrdersAllowed,
      allowOrders: stockDetails.ordersAllowed,
      boxQuantity: productDetails.boxQuantity,
      description: productDetails.description,
      // canadian: product.canadian,
      canadian: false,
      sellMultiple: priceDetails?.soldInMultiplesOf ?? 1,
      statusCode: productDetails.statusCode,
      unit: productDetails.unit,
      vendorName: productDetails.vendor.name,
      vendorNum: productDetails.vendor.vendorNumber,
      returnable: productDetails.returnable,
    };

    return result;
  });
}
