@let product = typedDetails();

<ideal-product-basic-details />

@if (product.boxQuantity > 1) {
  <div line *renderInProductDetailsLayout="LayoutTarget.Info">
    <label i18n="@@products.box-quantity.label">Box Quantity</label><span value>{{ product.boxQuantity }}</span>
  </div>
}

@if (product.returnable === false) {
  <div line class="warning">
    <span value i18n="@@products.non-returnable.message" *renderInProductDetailsLayout="LayoutTarget.Info"
      >This product is non-returnable</span
    >
  </div>
}
<ideal-product-image-viewer *renderInProductDetailsLayout="LayoutTarget.Images" />

<ideal-product-purchase *renderInProductDetailsLayout="LayoutTarget.Tools" />
<ideal-product-stock *renderInProductDetailsLayout="LayoutTarget.Tools" />
<ideal-product-certs *renderInProductDetailsLayout="LayoutTarget.Badges" />

<ideal-product-cross-references *renderInProductDetailsLayout />
<ideal-product-description *renderInProductDetailsLayout="LayoutTarget.LongDescription"></ideal-product-description>

<ideal-product-specifications *renderInProductDetailsLayout />
<ideal-product-links *renderInProductDetailsLayout />
<ideal-product-alternates *renderInProductDetailsLayout />
<ideal-product-complimentary *renderInProductDetailsLayout />
