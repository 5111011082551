import { JsonPipe } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, computed, effect, inject, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ImageComponent, LoadingContainerComponent } from 'common-ui';
import { ProductDetailsComponentStore, productIdentifierAttribute, productIdentifierToString } from 'products-data';

@Component({
  selector: 'ideal-product-vendor-logo',
  imports: [ImageComponent, RouterModule, LoadingContainerComponent, JsonPipe],
  templateUrl: './product-vendor-logo.component.html',
  styleUrl: './product-vendor-logo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductDetailsComponentStore],
  host: {
    '[class.link-enabled]': 'enableShopLink()',
  },
})
export class ProductVendorLogoComponent {
  public readonly productId = input.required({ transform: productIdentifierAttribute });
  public readonly enableShopLink = input(false, { transform: booleanAttribute });

  private readonly _store = inject(ProductDetailsComponentStore);

  protected readonly isLoading = this._store.productLoading;
  protected readonly vendorNumber = computed(() => this._store.product()?.vendor.vendorNumber);
  protected readonly logoUrl = computed(() => this._store.product()?.vendor.logoUrl ?? undefined);
  protected readonly hasShop = computed(() => this._store.product()?.vendor.hasShop ?? false);

  protected readonly shopLink = computed(() => (this.hasShop() ? ['/vendors', this.vendorNumber()] : ['/products']));
  protected readonly shopLinkParams = computed(() => (this.hasShop() ? {} : { ao: true, ven: this.vendorNumber() }));

  constructor() {
    effect(() => this._store.setSelectedProduct(productIdentifierToString(this.productId())));
  }
}
