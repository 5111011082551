import { computed, Directive, input } from '@angular/core';
import { RenderInSourceDirectiveBase } from 'common-ui';
import { ProductDetailRenderTarget, ProductDetailRenderTargetComponents } from './ProductDetailRenderTarget';

@Directive({
  selector: '[renderInProductDetailsLayout]',
  standalone: true,
})
export class RenderInProductDetailsLayout extends RenderInSourceDirectiveBase {
  public readonly target = input<ProductDetailRenderTarget, ProductDetailRenderTarget | string | undefined>(
    ProductDetailRenderTarget.Default,
    {
      alias: 'renderInProductDetailsLayout',
      transform: (value) => (value as ProductDetailRenderTarget) ?? ProductDetailRenderTarget.Default,
    },
  );
  protected readonly renderIn = computed(
    () => ProductDetailRenderTargetComponents[this.target()] || ProductDetailRenderTargetComponents[ProductDetailRenderTarget.Default],
  );
}
