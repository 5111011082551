import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, signal } from '@angular/core';
import { ProductSectionComponent } from '../../../components/product-section/product-section.component';
import { ProductCommonDetailsBaseComponent } from '../product-common-details-base.component';
import { TextInputComponent } from 'forms-ui';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TextFormControl } from 'forms-data';
import { LoadingContainerComponent } from 'common-ui';

@Component({
  selector: 'ideal-product-cross-references',
  imports: [ProductSectionComponent, TextInputComponent, ReactiveFormsModule, JsonPipe, LoadingContainerComponent],
  standalone: true,
  templateUrl: './product-cross-references.component.html',
  styleUrls: ['../styles/product-base.scss', '../styles/loading.scss', './product-cross-references.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.loading]': 'isLoading()',
    '[class.no-content]': '!hasContent()',
  },
})
export class ProductCrossReferencesComponent extends ProductCommonDetailsBaseComponent {
  protected readonly isLoading = signal(false);
  protected readonly crossReferences = computed(() => this.store.crossReferences() ?? []);
  protected readonly hasContent = computed(() => this.crossReferences().length !== 0);

  protected form = new FormGroup({});

  constructor() {
    super();
    effect((clean) => {
      const xrefs = this.crossReferences();
      xrefs.forEach((xref) => {
        const control = new TextFormControl(xref.crossReference);
        this.form.addControl(xref.customerNumber, control);
      });
      clean(() => {
        for (const k in this.form.controls) {
          this.form.removeControl(k);
        }
      });
    });
  }
}
