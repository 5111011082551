import { inject } from '@angular/core';
import { ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';
import { ProductKeywordsEntity } from './entities/ProductKeywordsEntity';
import { withCachedEntityStore } from 'state-data';

export const ProductKeywordsStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductKeywordsEntity) => p.id,
    load: (productId: string) =>
      inject(ProductApi)
        .getKeywords({ productId })
        .pipe(map((keywords) => ({ id: productId, keywords }))),
    cacheSeconds: 60,
  }),
);
