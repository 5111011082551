@let product = details();
@let boxQuantity = product?.boxQuantity ?? 1;

@if (boxQuantity > 1) {
  <div>boxQuantity: {{ boxQuantity | number }}</div>
}
@if (productItemId()) {
  <ideal-product-price-simple [productId]="productItemId()"></ideal-product-price-simple>
}

<ideal-loading-container class="block" [loading]="!ready()">
  <cart-add-remove-button [item]="legacyListItem()"></cart-add-remove-button>
</ideal-loading-container>

<product-list-item-stock [item]="legacyListItem()" />
