import { inject } from '@angular/core';
import { ProductApi, ProductDetails } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { withCachedEntityStore } from 'state-data';

export const ProductDetailsStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductDetails) => p.id.lineCode + p.id.partNumber,
    load: (productId: string) => inject(ProductApi).getDetails({ productId }),
    cacheSeconds: 60,
  }),
);
