<ideal-loading-container [loading]="store.isLoading()">
  <div price-info>
    @if (store.callForPricing()) {
      <div call-for-price><mat-icon>phone</mat-icon>Price</div>
    } @else {
      @if (store.hasPrice()) {
        <div price main-price>
          @let priceDetails = mainPrice();
          @if (priceDetails.isSale) {
            <div base>{{ priceDetails.priceBase | currency }}</div>
          }
          <div current>{{ priceDetails.price | currency }}</div>
          <div per i18n="@@product.units.label" per>
            {{ priceDetails.pricePer | number }} {{ priceDetails.pricePer | i18nPlural: store.unitLabel() }}
          </div>
        </div>

        @if (hasContainerPrice()) {
          <div divider>or</div>
          <div price container-price>
            @let priceDetails = containerPrice();
            @if (priceDetails.isSale) {
              <div base>{{ priceDetails.priceBase | currency }}</div>
            }
            <div current>{{ priceDetails.price | currency }}</div>
            <div per i18n="@@product.units.label" per>
              box of {{ priceDetails.pricePer | number }} {{ priceDetails.pricePer | i18nPlural: store.unitLabel() }}
            </div>
          </div>
        }
      }
    }
  </div>
</ideal-loading-container>
