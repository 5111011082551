import { CurrencyPipe, DecimalPipe, I18nPluralPipe } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, effect, inject, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { LoadingContainerComponent } from 'common-ui';
import { ProductPricingComponentStore } from 'products-data';

@Component({
  selector: 'ideal-product-price-simple',
  imports: [LoadingContainerComponent, CurrencyPipe, DecimalPipe, I18nPluralPipe, MatIcon],
  standalone: true,
  templateUrl: './product-price-simple.component.html',
  styleUrl: './product-price-simple.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductPricingComponentStore],
  host: {
    '[class.sale]': 'store.isSale()',
    '[class.show-units]': '!hideUnits()',
  },
})
export class ProductPriceSimpleComponent {
  public readonly productId = input.required<string>();
  public readonly hideUnits = input(false, { transform: booleanAttribute });
  protected readonly store = inject(ProductPricingComponentStore);

  constructor() {
    effect(() => {
      this.store.setSelectedProduct(this.productId());
    });
  }
}
