import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ProductSectionComponent } from '../../../components/product-section/product-section.component';
import { ProductCommonDetailsBaseComponent } from '../product-common-details-base.component';
import { LoadingContainerComponent } from 'common-ui';
import { ProductCardComponent } from '../../../../product-card';

@Component({
  selector: 'ideal-product-alternates',
  imports: [ProductSectionComponent, LoadingContainerComponent, ProductCardComponent],
  standalone: true,
  templateUrl: './product-alternates.component.html',
  styleUrls: ['../styles/product-base.scss', '../styles/loading.scss', './product-alternates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.loading]': 'isLoading()',
    '[class.no-content]': '!hasContent()',
  },
})
export class ProductAlternatesComponent extends ProductCommonDetailsBaseComponent {
  protected readonly isLoading = this.store.alternateProductsLoading;
  protected readonly alternateProducts = computed(() => this.store.alternateProducts() ?? []);
  protected readonly hasContent = computed(() => this.alternateProducts().length !== 0);
}
