import { computed, Directive, inject, Signal } from '@angular/core';
import { ProductCardComponentStore } from 'products-data';

@Directive()
export abstract class ProductCardCommonDetailsBaseComponent {
  protected readonly store = inject(ProductCardComponentStore);
  protected readonly detailsRecord = this.store.productRecord;
  protected readonly details = this.store.product;
  protected abstract readonly hasContent: Signal<boolean>;
  protected abstract readonly isLoading: Signal<boolean>;
  protected readonly ready = computed(() => !this.isLoading() && this.hasContent());
}
