import { ChangeDetectionStrategy, Component, computed } from '@angular/core';
import { ProductSectionComponent } from '../../../components/product-section/product-section.component';
import { ProductCommonDetailsBaseComponent } from '../product-common-details-base.component';
import { LoadingContainerComponent } from 'common-ui';

@Component({
  selector: 'ideal-product-specifications',
  imports: [ProductSectionComponent, LoadingContainerComponent],
  standalone: true,
  templateUrl: './product-specifications.component.html',
  styleUrls: ['../styles/product-base.scss', '../styles/loading.scss', './product-specifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.loading]': 'isLoading()',
    '[class.no-content]': '!hasContent()',
  },
})
export class ProductSpecificationsComponent extends ProductCommonDetailsBaseComponent {
  protected readonly isLoading = this.store.specificationsLoading;
  protected readonly specifications = computed(() => this.store.specifications() ?? []);
  protected readonly hasContent = computed(() => this.specifications().length !== 0);
}
