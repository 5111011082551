import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RenderInTargetComponentBase } from 'common-ui';

@Component({
  selector: 'ideal-render-target-product-details-long-description',
  standalone: true,
  imports: [NgTemplateOutlet],
  template: ` @for (item of renderItems(); track item) {
    <ng-container
      [ngTemplateOutlet]="item.template"
      [ngTemplateOutletContext]="getItemContext(item, $index)"
      [ngTemplateOutletInjector]="item.injector"
    ></ng-container>
  }`,
  styles: `
    :host {
      display: contents;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductDetailLongDescriptionRenderTargetComponent extends RenderInTargetComponentBase {
  public override get context() {
    return undefined;
  }
}
