<ideal-loading-container class="block" [loading]="!ready()">
  <ideal-product-section
    i18n-title="@@products.links.label"
    title="Links"
    navAnchorId="app.main-menu/products/details/links"
    navAnchorHeading="Product"
    i18n-navAnchorHeading="@@products.nav.product.heading"
    [navAnchorHidden]="!ready()"
  >
    <div class="links">
      @for (item of links(); track $index) {
        <ideal-file-button [url]="item.url" [label]="item.label" />
      }
    </div>
  </ideal-product-section>
</ideal-loading-container>
