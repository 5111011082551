@let product = details();

<ideal-product-vendor-logo
  [productId]="product?.id ?? ''"
  *renderInProductDetailsLayout="LayoutTarget.Tools"
  enableShopLink="true"
></ideal-product-vendor-logo>

<ideal-loading-container class="vendor" *renderInProductDetailsLayout="LayoutTarget.ImportantInfo" class="block">
  <a [routerLink]="['/products']" [queryParams]="{ ven: product?.vendor?.vendorNumber }">{{ product?.vendor?.name }}</a>
</ideal-loading-container>

<ideal-loading-container class="description" *renderInProductDetailsLayout="LayoutTarget.ImportantInfo" class="block">
  <h2>
    {{ product?.description }}
  </h2>
</ideal-loading-container>
<ideal-loading-container class="product-id" *renderInProductDetailsLayout="LayoutTarget.ImportantInfo" class="block">
  <div>{{ product?.id?.lineCode }}{{ product?.id?.partNumber }}</div>
</ideal-loading-container>

@if (specSheetUrl()) {
  <ideal-file-button [url]="specSheetUrl()" label="Download Spec Sheet" *renderInProductDetailsLayout="LayoutTarget.Badges" />
}
