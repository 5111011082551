import { ProductDetailBadgesRenderTargetComponent } from './product-details-badges.render-target.component';
import { ProductDetailDefaultRenderTargetComponent } from './product-details-default.render-target.component';
import { ProductDetailFooterRenderTargetComponent } from './product-details-footer.render-target.component';
import { ProductDetailHeaderRenderTargetComponent } from './product-details-header.render-target.component';
import { ProductDetailIMagesRenderTargetComponent } from './product-details-images.render-target.component';
import { ProductDetailImportantInfoRenderTargetComponent } from './product-details-important-info.render-target.component';
import { ProductDetailInfoRenderTargetComponent } from './product-details-info.render-target.component';
import { ProductDetailLongDescriptionRenderTargetComponent } from './product-details-long-description.render-target.component';
import { ProductDetailToolsRenderTargetComponent } from './product-details-tools.render-target.component';

export const RenderTargetComponents = [
  ProductDetailImportantInfoRenderTargetComponent,
  ProductDetailInfoRenderTargetComponent,
  ProductDetailHeaderRenderTargetComponent,
  ProductDetailIMagesRenderTargetComponent,
  ProductDetailFooterRenderTargetComponent,
  ProductDetailLongDescriptionRenderTargetComponent,
  ProductDetailToolsRenderTargetComponent,
  ProductDetailBadgesRenderTargetComponent,
  ProductDetailDefaultRenderTargetComponent,
];
