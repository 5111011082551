import { DecimalPipe, NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, inject, input } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ProductIdentifier } from '@idealsupply/products-api-angular-client';
import { ImageComponent } from 'common-ui';
import { ProductCardComponentStore, productIdentifierAttribute, PRODUCTS_ROUTE_PATH } from 'products-data';
import { ProductCardCommonDetailsBaseComponent } from './ProductCardCommonDetailsBaseComponent';
import { ProductCardComponentTypeDefinitions } from './ProductCardComponentTypeDefinitions';

@Component({
  selector: 'ideal-product-card',
  imports: [MatCardModule, ImageComponent, RouterModule, NgComponentOutlet],
  standalone: true,
  templateUrl: './product-card.component.html',
  styleUrl: './product-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductCardComponentStore],
})
export class ProductCardComponent extends ProductCardCommonDetailsBaseComponent {
  public readonly productId = input.required<ProductIdentifier, string | ProductIdentifier>({
    transform: productIdentifierAttribute,
    alias: 'product',
  });
  public readonly productPath = input<string[]>();

  private readonly _productRoutePath = inject(PRODUCTS_ROUTE_PATH);
  private readonly _route = inject(ActivatedRoute);
  protected override hasContent = computed(() => !!this.store.product());
  protected override isLoading = computed(() => !!this.store.productLoading());
  protected readonly productIdString = computed(() => `${this.productId().lineCode}${this.productId().partNumber}`);

  protected readonly productCmd = computed(() => {
    const path = this.productPath();
    const id = this.productIdString();
    return path ? [...path, id] : ['/', this._productRoutePath, id];
  });
  protected readonly relativeTo = computed(() => (this.productPath() ? this._route : undefined));

  protected readonly image = computed(() => `https://api.idealsupply.com/products/v0/${this.productIdString()}/thumbnail`);

  protected readonly typeComponent = computed(() => {
    const type = this.details()?.type;
    return type ? ProductCardComponentTypeDefinitions[type as keyof typeof ProductCardComponentTypeDefinitions] : null;
  });

  constructor() {
    super();
    effect(() => {
      this.store.setSelectedProduct(this.productIdString());
    });
  }
}
