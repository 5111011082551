import { inject } from '@angular/core';
import { Product, ProductApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { withCachedEntityStore } from 'state-data';

export const ProductStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: Product) => p.id.lineCode + p.id.partNumber,
    load: (productId: string) => inject(ProductApi).getProduct({ productId }),
    cacheSeconds: 60,
  }),
);
