import { inject } from '@angular/core';
import { StandardProductItemsApi } from '@idealsupply/products-api-angular-client';
import { signalStore } from '@ngrx/signals';
import { AuthenticationService } from 'authentication-data';
import { map } from 'rxjs';
import { withCachedEntityStore } from 'state-data';
import { ProductStockEntity, StockLevel } from './entities/ProductStockEntity';

export const ProductStockStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductStockEntity) => p.id,
    load: (productId: string) => {
      const user = inject(AuthenticationService).user;
      return inject(StandardProductItemsApi)
        .getProductItemStock({ productId })
        .pipe(
          map((r) => {
            const homeBranch = user()?.homeBranch;
            r.backOrdersAllowed;
            r.ordersAllowed;
            return {
              id: productId,
              homeBranch,
              backOrdersAllowed: r.backOrdersAllowed,
              ordersAllowed: r.ordersAllowed,
              stock: r.branchStock.map(
                (s) =>
                  ({
                    branchId: s.branchId,
                    branchName: s.branchName,
                    isHomeBranch: s.branchId === homeBranch,
                    quantity: s.quantity,
                    quantityBackOrdered: s.quantityBackOrdered,
                  }) as StockLevel,
              ),
            };
          }),
        );
    },
    cacheSeconds: 60,
  }),
);
