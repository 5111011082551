import { computed, Directive, inject, Signal } from '@angular/core';
import { ProductDetailsComponentStore } from 'products-data';

@Directive()
export abstract class ProductCommonDetailsBaseComponent {
  protected readonly store = inject(ProductDetailsComponentStore);
  protected readonly layout = this.store.layout;
  protected readonly detailsRecord = this.store.productRecord;
  protected readonly details = this.store.product;
  protected abstract readonly hasContent: Signal<boolean>;
  protected abstract readonly isLoading: Signal<boolean>;
  protected readonly ready = computed(() => !this.isLoading() && this.hasContent());
}
