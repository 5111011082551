export const ProductUnitLabels = {
  ea: {
    other: $localize`:@@products.units.each:each`,
  },
  mr: {
    '=1': $localize`:@@products.units.meter:meter`,
    other: $localize`:@@products.units.meters:meters`,
  },
  rl: {
    '=1': $localize`:@@products.units.role:role`,
    other: $localize`:@@products.units.roles:roles`,
  },
  pk: {
    '=1': $localize`:@@products.units.pack:pack`,
    other: $localize`:@@products.units.packs:packs`,
  },
} as const;
