import { ChangeDetectionStrategy, Component, ElementRef, inject } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { breakpointValue, ifThen, otherwise, Size } from '@cpangular/rxjs';
import { IfHasRenderInSourcesDirective } from 'common-ui';
import { NavAnchorDirective } from 'navigation-ui';
import { OneColumnLayoutComponent } from 'page-layouts-ui';
import { ProductDetailsComponentStore, ProductDetailsLayout } from 'products-data';
import { Observable, tap } from 'rxjs';
import { ProductDetailRenderTarget, ProductDetailRenderTargetComponents } from './render-targets';
import { RenderTargetComponents } from './render-targets/RenderTargetComponents';

@Component({
  selector: 'ideal-product-details-layout',
  imports: [OneColumnLayoutComponent, RenderTargetComponents, NavAnchorDirective, IfHasRenderInSourcesDirective],
  standalone: true,
  templateUrl: './product-details-layout.component.html',
  styleUrl: './product-details-layout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[attr.layout]': 'pageLayout()',
  },
})
export class ProductDetailsLayoutComponent {
  private readonly _store = inject(ProductDetailsComponentStore);

  protected readonly Layout = ProductDetailsLayout;
  protected readonly LayoutTargets = ProductDetailRenderTarget;
  protected readonly LayoutComponents = ProductDetailRenderTargetComponents;

  private _elementRef = inject<ElementRef<HTMLElement>>(ElementRef);
  protected readonly pageLayout = toSignal(
    (
      breakpointValue(
        this._elementRef.nativeElement,
        ifThen((s: Size) => s.width < 600, ProductDetailsLayout.OneCol),
        ifThen((s: Size) => s.width < 800, ProductDetailsLayout.TwoColSmall),
        ifThen((s: Size) => s.width < 1200, ProductDetailsLayout.TwoCol),
        otherwise(ProductDetailsLayout.ThreeCol),
      ) as Observable<ProductDetailsLayout>
    ).pipe(tap((layout) => this._store.setLayout(layout))),
    { requireSync: true },
  );
}
