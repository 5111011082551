import { inject } from '@angular/core';
import { StockApi } from '@idealsupply/ngclient-webservice-inventory';
import { signalStore } from '@ngrx/signals';
import { map } from 'rxjs';
import { stringToProductIdentifier } from '../ProductUtil';
import { ProductStockLocationEntity } from './entities/ProductStockLocationEntity';
import { withCachedEntityStore } from 'state-data';

export const ProductStockLocationStore = signalStore(
  { providedIn: 'root' },
  withCachedEntityStore({
    selectId: (p: ProductStockLocationEntity) => p.id,
    load: (id: string) =>
      inject(StockApi)
        .getStockLocationsForProducts([stringToProductIdentifier(id)])
        .pipe(map((data) => ({ id, stockLocation: data[0].stockLocation }))),
    cacheSeconds: 60,
  }),
);
